import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Request} from '../../../model/request/request';
import {RequestService} from '../../../service/request.service';
import {EnumService} from '../../../service/enum.service';
import {Enum} from '../../../model/enum';
import {UserService} from '../../../service/user.service';
import {environment} from '../../../../environments/environment';
import {EventBusService, Events} from '../../../service/event-bus.service';
import {Subscription} from 'rxjs';
import {User} from '../../../model/user/user';

declare var $;
declare var moment;

@Component({
  selector: 'app-register-country-manufacture',
  templateUrl: './register-country-manufacture.component.html',
  styleUrls: ['./register-country-manufacture.component.scss']
})
export class RegisterCountryManufactureComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  isChecked1: boolean = false;
  isChecked2: boolean = false;
  isChecked3: boolean = false;

  step = 1;
  stepTabSend = true;
  stepTabNext = false;
  createaAt: string;

  modelRequest: Request = new Request();
  modelRequestResult: Request = new Request();
  listChannel: Enum[] = [];
  listMeasure: Enum[] = [];
  listDispatched: Enum[] = [];
  listPresentation: Enum[] = [];
  listPort: Enum[] = [];
  listDueDate = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25
  ];
  listLike: Request[] = [];

  modelDescription: any = {
    step0: '',
    step1: '',
    step1lbl: '',
    step1isB: false,
    step1B: '',
    step1Blbl: '',
    step2: '',
    step3: '',
    step4: '',
    step4isBulk: '',
    step5isSpace: false,
    step5: '',
    step6: '',
    step7: ''
  };

  modelDescriptionCountry = [
    {
      name: 'ARG',
      description: 'argentina',
      thumb: 'argentina.svg',
    },
    {
      name: 'BOL',
      description: 'bolivia',
      thumb: 'bolivia.svg',
    },
    {
      name: 'GUA',
      description: 'guatemala',
      thumb: 'guatemala.svg',
    },
    {
      name: 'PAN',
      description: 'panama',
      thumb: 'panama.svg',
    },
    {
      name: 'CHI',
      description: 'chile',
      thumb: 'chile.svg',
    },
    {
      name: 'COL',
      description: 'colombia',
      thumb: 'colombia.svg',
    },
    {
      name: 'ECU',
      description: 'equador',
      thumb: 'equador.svg',
    },
    {
      name: 'MEX',
      description: 'mexico',
      thumb: 'mexico.svg',
    },
    {
      name: 'PER',
      description: 'peru',
      thumb: 'peru.svg',
    },
    {
      name: 'PAR',
      description: 'paraguai',
      thumb: 'paraguay.svg',
    },
    {
      name: 'URU',
      description: 'uruguai',
      thumb: 'uruguai.svg',
    },
    {
      name: 'VEN',
      description: 'venezuela',
      thumb: 'venezuela.svg',
    },
    {
      name: 'ANG',
      description: 'angola',
      thumb: 'angola.svg',
    },
    {
      name: 'MOZ',
      description: 'moçambique',
      thumb: 'mocambique.svg',
    },
  ];

  modalDescriptionStep = 0;
  modalDescriptionStr = '';
  modelDescriptionPortariaOrig = '';
  modelDescriptionPortaria = '';

  isEdit = false;
  stepOneDisabled = false;
  stepTwoDisabled = false;
  stepThreeDisabled = false;
  stepFourDisabled = false;
  stepFiveDisabled = false;

  btnOneDisabled = false;
  btnTwoDisabled = false;
  btnThreeDisabled = false;
  btnFourDisabled = false;
  btnFiveDisabled = false;

  isStepFourCountryAdd = false;

  eventbusSub: Subscription;

  country: string;
  isCeam: boolean;

  arrCheckPeso = ['XPE', 'CR', 'POM', 'SOL NAS', 'SOL OR'];

  user: User;
  modelResetStep = {
    step: null,
    motive: null
  };

  showStepOneCountries = false;

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public requestService: RequestService,
              public notificationService: UserService,
              public enumService: EnumService,
              private eventbus: EventBusService) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
    this.country = localStorage.getItem('country');
    this.isCeam = localStorage.getItem('isCeam') === 'true';
    this.eventbusSub = this.eventbus.on(Events.actionLanguage, () => this.handlerEnums());
    this.route2.params.subscribe(data => {
      if (!this.isNullOrUndefined(data.id)) {
        this.loading.show();
        this.isEdit = true;
        this.getid(data.id);
        setTimeout(() => {
          this.step = this.modelRequest.step;
          // this.handlerBack(this.modelRequest.step);

          if (this.checkProfile()) {
            this.handlerStart(this.modelRequest.step);
            // this.handlerCheckNext();
            // $('#myTab').find('a:eq(' + this.modelRequest.step + ')').tab('show');
            // this.handlerBack(this.modelRequest.step);
          } else {
            if (this.modelRequest.status === 'disapprove' || this.modelRequest.status === 'approve') {
              this.handlerStart(this.modelRequest.step);
            } else {
              this.handlerStart(this.modelRequest.step + 1);
            }
            // $('#myTab').find('a:eq(' + (this.modelRequest.step + 1) + ')').tab('show');
            // this.handlerBack(this.modelRequest.step + 1);
          }

          if (this.modelRequest.step === 3) {
            this.actionLike();
          }
          this.setDatePicker();
          this.loading.hide();


          this.setDatePicker();
          this.setEventModalDescription();

        }, 1500);
      } else {
        this.modelRequest.area_400 = 'PMOINT';
        this.modelRequest.flowType = 'pmoint';
        $(() => {
          $('#myTab').find('a:eq(0)').tab('show');
        });
      }
    });



    $(() => {
      this.blockClickStep();
      this.createaAt = moment();
      this.actionChannel();
      this.actionMeasure();
      this.actionDispatched();
      this.actionPresentation();
      this.actionPort();

      setTimeout(() => {
        this.setEventsStart();
      }, 1000);
    });
    // setTimeout(() => {
    //     $('#modalDescriptionForm').modal('show');
    // }, 1000);
  }

  ngAfterViewInit(): void {
    this.notificationService.user.subscribe({
      next: data => {
        this.user = data as User;
      }, error: err => super.onError(err)
    });
  }

  setEventsStart() {
    $('[data-toggle="popover"]').popover({
      trigger: 'focus'
    });


    $('[data-toggle="tooltip"]').tooltip()


    $('#mytabF-modal').on('click', 'a', (e) => { // Desabilitar click na tab modal descritivo
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    });

    $('#mytabF-modal').on('show.bs.tab', (e) => {
      // console.log('mytabF-modal tab show');
    });
    $('#mytabF-modal').on('shown.bs.tab', (e) => {
      // console.log('mytabF-modal tab show 2');
    });

    $('#myTab').on('show.bs.tab', (e) => {
      // console.log('this.step show');
      // console.log(this.step);
    });

    $('#myTab').on('shown.bs.tab', (e) => {
      // log('this.step shown');
      // console.log(this.step);
      this.handlerCheckNext();
    });
  }

  ngOnDestroy(): void {
    this.eventbusSub.unsubscribe();
    $('#modalDescriptionForm').modal('hide');
    this.destroyModal('modalDescriptionForm');
    this.destroyModal('modalChecklist');
  }

  handlerEnums() {
    this.actionChannel();
    this.actionMeasure();
    this.actionDispatched();
    this.actionPresentation();
    this.actionPort();
  }

  checkProfile() {
    const profile = this.notificationService.getUserLocal().profile;

    if (profile == 'consult')
      return true;

    const stepActive = this.modelRequest.step;
    const profiles = ['commercial', 'ar_local', 'ar_inter', 'arts', 'register'];
    const index = profiles.indexOf(profile);
    if (index >= 0 && stepActive >= (index + 1)) {
      return true;
    }
    return false;
  }

  blockClickStep() {
    $('.nav-link').on('click', (e) => {
      if (!$(e.currentTarget).hasClass('finish')) {
        e.preventDefault();
        e.stopImmediatePropagation();
      } else {
        const step = Number($(e.currentTarget)[0].id.replace(/([^\d])+/gim, ''));
        this.handlerBack(step);
      }
    });
  }

  handlerStart(step) {
    this.step = step;
    this.getid(this.modelRequest.id);
    $('#myTab').find('a:eq(' + (step - 1) + ')').tab('show');
    // this.handlerCheckNext();
    this.handlerCheckBar(step);
    this.handlerCheckDisable(step);
    if (this.modelRequest.countryAdditional.length > 0) {
      this.isStepFourCountryAdd = true;
    }
  }

  handlerNext(e) {
    this.step = e + 1;
    $('#myTab').find('a:eq(' + e + ')').tab('show');
    this.handlerCheckBar(e + 1);
    this.handlerCheckDisable(e + 1);
  }

  handlerBack(step) {
    this.step = step;
    this.getid(this.modelRequest.id);
    $('#myTab').find('a:eq(' + (step - 1) + ')').tab('show');
    this.handlerCheckBar(step);
    this.handlerCheckDisable(step - 1);
  }

  handlerCheckBar(i: number) {
    const arrFill = [
      'white',
      'linear-gradient(90deg, #FAE100 27%, #FFFFFF 27%)',
      'linear-gradient(90deg, #FAE100 50%, #FFFFFF 27%)',
      'linear-gradient(90deg, #FAE100 73%, #FFFFFF 27%)',
      'linear-gradient(90deg, #FAE100 100%, #FFFFFF 27%)'
    ];
    let el = null;
    if (this.modelRequest.status === 'disapprove' || this.modelRequest.status === 'approve') {
      el = $('#myTabF');
    } else {
      el = $('#myTab');
    }
    el.parent('.nav_step').css({
      background: arrFill[i - 1]
    });
    if (i === 3 && !this.isNullOrUndefined(this.modelDescriptionPortaria)) {
      this.setModelPortariaDescription();
    }
    if (this.modelRequest.status === 'disapprove' || this.modelRequest.status === 'approve') {
      // $('#myTabF').find('a.active').parent().prevAll().find('a').addClass('finish');
      // $('#myTabF').find('a.active').parent().nextAll().find('a').removeClass('finish');
    } else {
      $('#myTab').parent('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
    }
    // $('.nav_step').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
    // $('.nav_step').find('.nav').find('a.active').removeClass('active');
    /*$('#myTab').parent('.nav_step').find('.nav').find('li:eq(' + (i - 1) + ')').find('a').addClass('active finish')
        .parent().prevAll().find('a').addClass('finish').removeClass('active');*/
  }

  handlerCheckDisable(i: number) {
    const profile = this.notificationService.getUserLocal().profile;
    this.notificationService.getNotification();

    switch (this.modelRequest.step) {
      case 0:
        this.stepOneDisabled = false;
        this.stepTwoDisabled = true;
        this.stepThreeDisabled = true;
        this.stepFourDisabled = true;
        this.stepFiveDisabled = true;
        break;
      case 1:
        if(profile === 'commercial'){
          this.stepOneDisabled = false;
        }else{
          this.stepOneDisabled = true;
        }
        this.stepTwoDisabled = false;
        this.stepThreeDisabled = true;
        this.stepFourDisabled = true;
        this.stepFiveDisabled = true;
        break;
      case 2:
        this.stepOneDisabled = true;
        this.stepTwoDisabled = true;
        this.stepThreeDisabled = false;
        this.stepFourDisabled = true;
        this.stepFiveDisabled = true;
        break;
      case 3:
        if (this.modelRequest.status === 'archived' ){
          this.stepOneDisabled = true;
          this.stepTwoDisabled = true;
          this.stepThreeDisabled = true;
          this.stepFourDisabled = true;
          this.stepFiveDisabled = true;
          break;
        }else{
          this.stepOneDisabled = true;
          this.stepTwoDisabled = true;
          this.stepThreeDisabled = profile !== 'arts';
          this.stepFourDisabled = false;
          this.stepFiveDisabled = true;
          break;
        }
      case 4:
        if (this.modelRequest.status === 'archived' ){
          this.stepFiveDisabled = false;
          console.log('aqii')
          console.log(this.modelRequest.status)
        }else{
          this.stepFiveDisabled = true;
        }
        this.stepOneDisabled = true;
        this.stepTwoDisabled = true;
        this.stepThreeDisabled = true;
        this.stepFourDisabled = true;
      case 5:
        if (this.modelRequest.status === 'archived' ){
          this.stepFiveDisabled = true;
        }
        this.stepOneDisabled = true;
        this.stepTwoDisabled = true;
        this.stepThreeDisabled = true;
        this.stepFourDisabled = true;
        this.stepFiveDisabled = false;
        break;
    }
  }

  handlerCheckNext() {
    const profile = this.notificationService.getUserLocal().profile;

    const stepActive = this.modelRequest.step;
    const profiles = ['commercial', 'ar_local', 'ar_inter', 'arts', 'register', 'administrator'];

    const profileStep = profiles.indexOf(profile) + 1;
    if (profileStep > stepActive && profileStep === this.step && this.step < 5) {
      this.stepTabSend = true;
    } else if (profileStep === 6 && this.step > stepActive) {
      this.stepTabSend = true;
    } else {
      this.stepTabSend = false;
    }

    if (stepActive >= this.step && !this.stepTabSend && profileStep > this.step) {
      this.stepTabNext = true;
    } else if (this.step < stepActive) {
      this.stepTabNext = true;
    } else {
      this.stepTabNext = false;
    }

  }

  setDatePicker() {
    const data = this.isNullOrUndefined(this.modelRequest.recordValidity) ?
        moment().format('DD/MM/yyyy') :
        moment.utc(this.modelRequest.recordValidity).format('DD/MM/yyyy');

    $('.js-daterangepicker').on('keydown', function (event) {
      if (event.which === 13 || event.keyCode === 13) {
        $(this).blur();
        return false;
      }
    });

    $('.js-daterangepicker').daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: !this.isNullOrUndefined(this.modelRequest.recordValidity),
      startDate: data,
      drops: 'up',
      minDate: parseInt(moment().format('DD/MM/yyyy'), 10),
      maxDate: parseInt(moment().add('years', 10).format('DD/MM/yyyy'), 10),
      /*minYear: 2021,
      maxYear: 2050,*/
      locale: super.objLocaleDateRangePicker()
    }, (start, end, label) => {
      console.log('data aqui');
      this.modelRequest.recordValidity = start.utcOffset(0, true).format();
      this.setDatePicker();
    });
  }

  getid(id) {
    this.requestService.getId(id).subscribe({
      next: data => {
        this.modelRequest = data as Request;

        if (this.step) {
          this.modelRequestResult = Object.assign({}, this.modelRequest);
        }
        if (this.modelRequest.status !== 'open' && !this.isNullOrUndefined(this.modelRequest.status)) {
          this.stepOneDisabled = true;
          this.stepTwoDisabled = true;
          this.stepThreeDisabled = true;
          this.stepFourDisabled = true;
          this.stepFiveDisabled = true;
        }
        this.modelDescriptionPortariaOrig = this.modelRequest.description;
      }, error: err => super.onError(err)
    });
  }

  handlerNameProduct() {
    const a = this.modelRequest.reasonRequest === 0 ? this.translate.instant('general.launch') : this.translate.instant('general.newSendBulk');
    const b = this.modelRequest.concentrationType === 'brand' ? this.modelRequest.productBrand : this.modelRequest.molecule;
    const c = this.modelRequest.reasonRequest === 1 ? '' : this.modelRequest.productBrandType === 'original' ? this.translate.instant('general.original') : this.translate.instant('general.medicalSample');
    const d = this.modelRequest.reasonRequest === 1 ? '' : this.modelRequest.commercialPresentation;
    const str = `${a} ${b} ${this.modelRequest.concentration} ${c} ${d}`;
    this.modelRequest.name = str.toUpperCase();
  }

  request1() {
    const arrValidateFields = [
      {value: this.modelRequest.code, text: `${this.translate.instant('general.codigomodelo')}* <br>`},
      {value: this.modelRequest.reasonRequest, text: `${this.translate.instant('general.reasonRequest')}* <br>`},
      {value: this.modelRequest.molecule, text: `${this.translate.instant('general.molecule')}* <br>`},
      {value: this.modelRequest.concentration, text: `${this.translate.instant('general.concentration')}* <br>`},
      {value: this.modelRequest.area_400, text: `${this.translate.instant('general.area')}* <br>`},
      {
        value: this.modelRequest.concentrationType,
        text: `${this.translate.instant('general.brand')} / ${this.translate.instant('general.generic')}* <br>`
      },
    ];

    if (this.modelRequest.reasonRequest !== 1) {
      // @ts-ignore
      arrValidateFields.push({
        value: this.modelRequest.productBrandType,
        text: `${this.translate.instant('general.original')} / ${this.translate.instant('general.medicalSample')}* <br>`
      });
      // @ts-ignore
      arrValidateFields.push({
        value: this.modelRequest.commercialPresentation,
        text: `${this.translate.instant('general.commercialPresentation')}* <br>`
      });
    } else {
      this.modelRequest.productBrandType = null;
      this.modelRequest.commercialPresentation = null;
    }
    if (this.modelRequest.concentrationType === 'brand' && this.modelRequest.reasonRequest !== 1) {
      arrValidateFields.push({
        value: this.modelRequest.productBrand,
        text: `${this.translate.instant('general.productBrand')}* <br>`
      });
    }

    if (this.modelRequest.area_400 === 'PMOINT') {
      arrValidateFields.push({
        value: this.modelRequest.countrySolicitationPmoInt,
        text: `País solicitante* <br>`
      });
      arrValidateFields.push({
        value: this.modelRequest.countryManufacturingPmoInt,
        text: `País fabricante* <br>`
      });
    }

    if ((this.country === 'uruguai' || this.handlerCheckCountryVisible() || this.handlerCheckCountryVisible('ceam')) && this.modelRequest.area_400 !== 'PMOINT') {
      arrValidateFields.push({
        // @ts-ignore
        value: this.modelRequest.countryNotifications,
        text: `${this.translate.instant('general.pais')}* <br>`
      });
    }

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
      return;
    }

    if (!this.isNullOrUndefined(this.modelRequest.countryNotifications)) {
      const countries = [];
      this.modelRequest.countryNotifications.forEach((x, i) => {
        // @ts-ignore
        if (this.isNullOrUndefined(x.type)) {
          countries.push(x);
        } else {
          // @ts-ignore
          countries.push(x.country);
        }
      });
      this.modelRequest.countryNotifications = undefined;
      // @ts-ignore
      this.modelRequest.countryNotifications = countries;
    } else {
      // @ts-ignore
      this.modelRequest.countryNotifications.push(this.country);
    }

    this.handlerNameProduct(); // Set nome produto

    this.modelRequest.molecule = this.modelRequest.molecule.toUpperCase();
    this.modelRequest.concentration = this.modelRequest.concentration.toUpperCase();
    this.modelRequest.concentrationType = this.modelRequest.concentrationType.toUpperCase();
    this.modelRequest.commercialPresentation = this.modelRequest.commercialPresentation ?
        this.modelRequest.commercialPresentation.toUpperCase() : null;
    if (this.modelRequest.productBrand) {
      this.modelRequest.productBrand = this.modelRequest.productBrand.toUpperCase();
    }

    this.loading.show();
    this.requestService.step('one', this.modelRequest).subscribe({
      next: data => {
        this.modelRequest = data as Request;
        this.actionConfirmAlert();
        // this.handlerNext(1);
        // this.router.navigate(['/register/' + this.modelRequest.id]);
        // setTimeout(() => {
        //     this.loading.hide();
        //     this.handlerConfirm();
        // }, 1000);
      }, error: err => super.onError(err)
    });
  }

  actionPresentation() {
    this.enumService.getEnum('presentation').subscribe({
      next: data => {
        this.listPresentation = data as Enum[];
      }, error: err => super.onError(err)
    });
  }

  actionPort() {
    this.enumService.getEnum('port').subscribe({
      next: data => {
        this.listPort = data as Enum[];
      }, error: err => super.onError(err)
    });
  }

  // REQUEST 2
  actionChannel() {
    this.enumService.getEnum('canal_distribuicao').subscribe({
      next: data => {
        this.listChannel = data as Enum[];
      }, error: err => super.onError(err)
    });
  }

  actionMeasure() {
    this.enumService.getEnum('measure').subscribe({
      next: data => {
        this.listMeasure = data as Enum[];
      }, error: err => super.onError(err)
    });
  }

  actionDispatched() {
    this.enumService.getEnum('dispatched').subscribe({
      next: data => {
        this.listDispatched = data as Enum[];
      }, error: err => super.onError(err)
    });
  }

  request2() {
    const arrValidateFields: any = [
      {value: this.modelRequest.motive, text: `${this.translate.instant('novocadastro.etapa2.input1')} <br>`},
      {
        value: this.modelRequest.channelDistribution,
        text: `${this.translate.instant('novocadastro.etapa2.input2')} <br>`
      },
      {
        value: this.modelRequest.unitMeasure,
        text: `${this.translate.instant('novocadastro.etapa2.input3')} <br>`
      },
      {
        value: this.modelRequest.description,
        text: `${this.translate.instant('novocadastro.etapa2.input8')} <br>`
      }
    ];

    if (this.modelRequest.area_400 !== 'PMOINT') {
      arrValidateFields.push(
          {
            value: this.modelRequest.recordHolder,
            text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.detentor')} <br>`
          },
          {
            value: this.modelRequest.dispatchedBy,
            text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.expedido')} <br>`
          }
      );
    }

    /*if (!this.isNullOrUndefined(this.modelRequest.ministryRecord)) {
        arrValidateFields.push({
            value: this.modelRequest.recordValidity,
            text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.validade')} <br>`
        });
    }*/

    if (this.modelRequest.dueDate < 1) {
      arrValidateFields.push({
        value: this.modelRequest.dueDate = null,
        text: `${this.translate.instant('novocadastro.etapa2.input14')} <br>`
      });
    }

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
      if (this.modelRequest.dueDate < 1) {
        this.modelRequest.dueDate = 0;
      }
      return;
    }
    this.modelRequest.countryNotifications = undefined;
    this.loading.show();
    this.requestService.step('two', this.modelRequest).subscribe({
      next: data => {
        this.modelRequest = data as Request;
        this.actionConfirmAlert();
        // this.handlerNext(2);
        // this.loading.hide();
        setTimeout(() => {
          this.setDatePicker();
        }, 500);
        // this.handlerConfirm();
      }, error: err => super.onError(err)
    });
  }

  // REQUEST 3

  request3() {
    const arrValidateFields: any = [
      {
        value: this.modelRequest.motive,
        text: `${this.translate.instant('novocadastro.etapa2.input1')}, <br>`},
      {
        value: this.modelRequest.channelDistribution,
        text: `${this.translate.instant('novocadastro.etapa2.input2')}, <br>`
      },
      {
        value: this.modelRequest.unitMeasure,
        text: `${this.translate.instant('novocadastro.etapa2.input3')}, <br>`
      },
      {
        value: this.modelRequest.description,
        text: `${this.translate.instant('novocadastro.etapa2.input8')}, <br>`
      },
      // {
      //     value: this.modelRequest.recordHolder,
      //     text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.detentor')}, <br>`
      // },
      // {
      //     value: this.modelRequest.dispatchedBy,
      //     text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.expedido')}, <br>`
      // },
      {
        value: this.isChecked1 === false ? undefined :  this.isChecked1,
        text: `${this.translate.instant('Valide o termo 1')}, <br>`
      },
      {
        value: this.isChecked2 === false ? undefined :  this.isChecked2,
        text: `${this.translate.instant('Valide o termo 2')}, <br>`
      },
      {
        value: this.isChecked3 === false ? undefined :  this.isChecked3,
        text: `${this.translate.instant('Valide o termo 3')}, <br>`
      }
    ];

    if (this.modelRequest.area_400 !== 'PMOINT') {
      arrValidateFields.push(
          {
            value: this.modelRequest.recordHolder,
            text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.detentor')} <br>`
          },
          {
            value: this.modelRequest.dispatchedBy,
            text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.expedido')} <br>`
          }
      );
    }
    if (!this.isNullOrUndefined(this.modelRequest.ministryRecord)) {
      /*arrValidateFields.push({
          value: this.modelRequest.recordValidity,
          text: `${this.translate.instant('novocadastro.etapa4.secao2.card3.validade')}, <br>`
      });*/
      if (this.modelRequest.dueDate < 1) {
        arrValidateFields.push({value: this.modelRequest.dueDate = null, text: `Meses. <br>`});
      }
    }

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
      return;
    }
    this.modelRequest.countryNotifications = undefined;
    this.loading.show();
    this.requestService.step('three', this.modelRequest).subscribe({
      next: data => {
        this.modelRequest = data as Request;
        this.modelRequestResult = Object.assign({}, this.modelRequest);
        this.modelRequest.unifications = [];
        // this.handlerNext(3);
        // this.stepFourDisabled = false;
        this.actionConfirmAlert();
        this.actionLike();
        // this.loading.hide();
        // this.handlerConfirm();
      }, error: err => super.onError(err)
    });
  }

  // REQUEST 4

  actionLike() {
    this.requestService.like(this.modelRequest.id).subscribe({
      next: data => {
        this.listLike = data as Request[];
        this.modelRequest.unifications = [];
        this.listLike.forEach(x => {
          this.modelRequest.unifications.push({isSelected: false, isDefault: false, request: x});
        });
      }, error: err => super.onError(err)
    });
  }

  getResult(id) {
    this.requestService.getId(id).subscribe({
      next: data => {
        this.modelRequestResult = data as Request;
      }, error: err => super.onError(err)
    });
  }

  radioChecked(id) {
    if (this.isNullOrUndefined(id)) {
      this.getResult(this.modelRequest.id);
    }
    this.modelRequest.unifications.forEach(item => {
      if (item.request.id !== id) {
        item.isDefault = false;
        item.isSelected = false;
      } else {
        this.getResult(id);
        item.isDefault = true;
        item.isSelected = true;
      }
    });
  }

  radioCheckedDad() {
    if (!this.isNullOrUndefined(this.modelRequest.unifications)) {
      const result = this.modelRequest.unifications.filter(x => {
        return x.isDefault === true;
      });
      if (this.isNullOrUndefined(result)) {
        return true;
      } else {
        return false;
      }
    }
  }

  request4() {
    const arrValidateFields = [
      {
        value: this.modelRequestResult.description,
        text: `${this.translate.instant('novocadastro.etapa2.input8')}, <br>`
      },
      {value: this.modelRequest.ean, text: `EAN 13 <br>`},
      {value: this.modelRequest.dum, text: `DUM 14 <br>`},
    ];

    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
      return;
    }

    /*MONTAR DESCRITIVO*/
    this.modelRequest.description = this.modelRequestResult.description;

    if (this.modelRequest.ean.length !== 13) {
      this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}`);
      return false;
    }

    const list = [];
    if (!this.isNullOrUndefined(this.modelRequest.unifications)) {
      this.modelRequest.unifications.forEach(e => {
        if (e.isSelected) {
          list.push(e);
        }
      });
    }

    this.modelRequest.unifications = list;

    if (this.isNullOrUndefined(this.modelRequest.unifications)) {
      this.modelRequest.type = 'simple';
    } else {
      this.modelRequest.type = 'unification';
      // INSERIR SIGLA AL NO FINAL QUANDO É UM ITEM UNIFICADO
      // this.modelRequest.description = this.modelRequest.description '+ ' AL'';

      const arrValidateFieldsUnification = [
        {
          value: this.modelRequest.unificationObservation,
          text: `${this.translate.instant('novocadastro.etapa4.unificationObservation')}, <br>`
        },
      ];

      const stringErrorUnification = this.validateField(arrValidateFieldsUnification);
      if (!super.isNullOrUndefined(stringErrorUnification)) {
        this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringErrorUnification}`);
        return;
      }
    }

    if (this.isStepFourCountryAdd && this.modelRequest.countryAdditional.length === 0) {
      this.showMessage(this.translate.instant('modal.alert.primary'),
          `${this.translate.instant('general.countryAdditionalRequest')}`);
      return;
    }

    // this.modelRequest.ean = this.modelRequest.ean.toString()
    // this.modelRequest.dum = this.modelRequest.dum.toString()
    // this.modelRequest.countryNotifications = undefined;

    this.loading.show();
    this.requestService.step('four', this.modelRequest).subscribe({
      next: data => {
        this.modelRequest = data as Request;
        this.actionConfirmAlert();
        // this.handlerConfirm();
        // this.handlerNext(4);
        // this.loading.hide();
      }, error: err => super.onError(err)
    });
  }

  // REQUEST 5

  requestFinish(status) {
    if (status === 'approve') {
      const arrValidateFields = [
        {value: this.modelRequest.codeSap, text: `${this.translate.instant('general.codeSAPCreate')} <br>`},
      ];

      const stringError = this.validateField(arrValidateFields);

      if (!super.isNullOrUndefined(stringError)) {
        this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
        return;
      }
    } else if (status === 'disapprove') {
      const arrValidateFields = [
        {
          value: this.modelRequest.descriptionFinish,
          text: `${this.translate.instant('novocadastro2.descritivo')} <br>`
        },
      ];
      const stringError = this.validateField(arrValidateFields);

      if (!super.isNullOrUndefined(stringError)) {
        this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
        return;
      }

      if (this.modelRequest.descriptionFinish.length < 20) {
        this.showMessage(this.translate.instant('modal.alert.primary'),
            `${this.translate.instant('general.justifyMinCaracteres')}`);
        return;
      }
      if (this.step <= 4) {
        this.callServiceRequestFinishPrevSteps(status);
        return;
      }
    }
    this.modelRequest.countryNotifications = undefined;
    this.callServiceRequestFinish(status);
  }

  callServiceRequestFinishPrevSteps(s) {
    const a: any = {status: s, statusReason: this.modelRequest.descriptionFinish , stepReprove: this.modelRequest.stepReprove};
    this.modelRequest.status = s;
    this.loading.show();
    this.requestService.changeStatus(this.modelRequest.id, a).subscribe({
      next: data => {
        $('#modalRegisterJustify').modal('hide');
        this.router.navigate(['/my-list']);
        setTimeout(() => {
          this.loading.hide();
          this.handlerConfirm();
          $('#modalRegisterJustify').remove();
        }, 1000);
      }, error: err => super.onError(err)
    });
  }

  callServiceRequestFinish(status) {
    this.modelRequest.status = status;
    this.loading.show();
    this.requestService.step('five', this.modelRequest).subscribe({
      next: data => {
        $('#modalRegisterJustify').modal('hide');
        this.router.navigate(['/my-list']);
        setTimeout(() => {
          this.loading.hide();
          this.handlerConfirm();
          $('#modalRegisterJustify').remove();
        }, 1000);
      }, error: err => super.onError(err)
    });
  }

  handlerMaximumCharaters(description) {
    if (!this.isNullOrUndefined(description)) {
      const result = 900 - description.length;
      return result;
    } else {
      return 900;
    }
  }

  handlerCharSubscr($event: any, n: number) {
    $($event.target).val($($event.target).val().substring(0, n).replace(/\D/g, ''));
  }

  handlerStringSubscr($event: any, n: number) {
    $($event.target).val($($event.target).val().substring(0, n));
  }

  handlerEan13($event: any) {
    $($event.target).val($($event.target).val().substring(0, 13).replace(/\D/g, ''));
    if ($('#EAN').val() === '') {
      this.modelRequest.ean = '';
    }
    if ($($event.target).val().length === 13) {
      const ean = $($event.target).val();

      const A = '1' + ean.toString().slice(0, -1);

      let sumPar = 0;
      let sumImpar = 0;

      for (let i = 0; i <= 12; i++) {
        if (i % 2 === 0) {
          sumImpar += parseInt(A[i], 10);
        } else {
          sumPar += parseInt(A[i], 10);
        }
      }

      const resultSum = (sumImpar * 3) + sumPar;
      const resultSumString = resultSum.toString();
      const lastCharacter = resultSumString[resultSumString.length - 1];

      let B = 0;

      if (lastCharacter !== '0') {
        B = 10 - parseInt(lastCharacter, 10);
      }

      const finalResult = A + B.toString();
      this.modelRequest.dum = finalResult;
    }
  }


  actionConfirmAlert() {
    const profile = this.notificationService.getUserLocal().profile;
    let validate = true;

    switch (this.step) {
      case 1 :
        if (profile === 'commercial') {
          validate = false;
        }
        break;
      case 2 :
        if (profile === 'ar_local') {
          validate = false;
        }
        break;
      case 3 :
        if (profile === 'ar_inter') {
          validate = false;
        }
        break;
      case 4 :
        if (profile === 'arts') {
          validate = false;
        }
        break;
      case 5 :
        if (profile === 'register') {
          validate = false;
        }
        break;
    }

    setTimeout(() => {
      this.loading.hide();
      this.handlerConfirm();
    }, 1000);


    if (validate) {
      // this.handlerNext(this.step);
      if (this.step === 3 && this.modelRequest.bulk) {
        this.handlerNext(this.step + 1);
        this.stepFourDisabled = true;
        this.btnFourDisabled = false;
        this.stepThreeDisabled = true;
        this.btnThreeDisabled = false;
      } else {
        this.handlerNext(this.step);
      }
      this.router.navigate(['/register/' + this.modelRequest.id]);
    } else {
      this.router.navigate(['/my-list']);
    }

  }

  // MODAL DESCRITIVO
  setEventModalDescription() {
    $('#modalDescriptionForm').on('shown.bs.modal', (e) => {
      // this.modalDescriptionStep = 0;
    });
    $('#modalDescriptionForm').on('show.bs.tab', (e) => {
      const currentStep = $(e.target).parent().index() - 1 > 0 ? $(e.target).parent().index() - 1 : 0;
      const validationStep = this.getValidationStep(currentStep);


      if (!validationStep && currentStep !== 2 && currentStep !== 3 && currentStep !== 5 && currentStep !== 6) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.modalDescriptionStep = currentStep;
        super.showMessage(this.translate.instant('modal.alert.primary'), this.translate.instant('modal.alert.tertiary'), 'warning');
        return false;
      } else {
        // console.log('valido passou step: ' + currentStep);
      }
    });
    $('#modalDescriptionForm').on('shown.bs.tab', (e, i) => {
      setTimeout(() => {
        $('#modalDescriptionForm').find('.nav').find('a.active').parent().prevAll().find('a').addClass('finish');
        $('#modalDescriptionForm').find('.nav').find('a.active').parent().nextAll().find('a').removeClass('finish');
        const s = $('#modalDescriptionForm').find('.nav').find('a.active').parent().index();
        // if ((s === 5 || s === 6) && this.modelRequest.bulk) {
        if ((s === 5) && this.modelRequest.bulk) {
          this.modalDescriptionStep = 3;
          this.prevStepModalDescription();
        }
      });
    });
  }

  getValidationStep(n: number) {
    let returnValue = false;
    if (!this.isNullOrUndefined(this.modelDescription['step' + n])) {
      returnValue = true;
    }
    if (n === 1) {
      if (
          this.isNullOrUndefined(this.modelDescription.step1lbl)
          || (!this.isNullOrUndefined(this.modelDescription.step1B) && this.isNullOrUndefined(this.modelDescription.step1Blbl))
      ) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  prevStepModalDescription() {
    let s = this.modalDescriptionStep;
    if (s === 7 && this.modelRequest.bulk) {
      s = 5;
    }
    if (s === 6 && !this.arrCheckPeso.includes(this.modelDescription.step4)) {
      s = 5;
    }
    this.changeStepModalDescription(s - 1);

  }

  nextStepModalDescription() {
    let s = this.modalDescriptionStep;
    if (s === 4 && this.modelRequest.bulk) {
      console.log('a0=' + s);
      this.modelDescription.step5 = null;
      this.modelDescription.step6 = null;
      s = 6;
    } else if (s === 4 && !this.arrCheckPeso.includes(this.modelDescription.step4)) {
      console.log('a1=' + s);
      this.modelDescription.step5 = null;
      s = 5;
    } else if (s === 5 && this.arrCheckPeso.includes(this.modelDescription.step4) && this.isNullOrUndefined(this.modelDescription.step5)) {
      this.showMessage(this.translate.instant('modal.alert.primary'), this.translate.instant('modal.alert.tertiary'));
      return false;
    }

    console.log('b=' +s);
    this.changeStepModalDescription(s + 1);
  }

  finishStepModalDescription() {
    const str = this.getDescriptionString();
    if ((str.split('').length - 3) > 33) {
      this.showMessage(this.translate.instant('modal.alert.primary'), this.translate.instant('modal.alert.quartenary'));
      return false;
    }
    this.modelRequest.description = str;
    this.modelDescriptionPortariaOrig = this.modelRequest.description;
    $('#modalDescriptionForm').modal('hide');
    if (!this.isNullOrUndefined(this.modelDescriptionPortaria) && (this.modelRequest.step === 2 || this.modelRequest.step === 3)) {
      this.setModelPortariaDescription();
    }
  }

  getDescriptionString() {
    const st1 = this.modelDescription.step1 +
        (this.isNullOrUndefined(this.modelDescription.step1) ? '' : this.modelDescription.step1lbl)
        + (this.isNullOrUndefined(this.modelDescription.step1B) ? '' : '+') + this.modelDescription.step1B
        + (this.isNullOrUndefined(this.modelDescription.step1B) ? '' : this.modelDescription.step1Blbl);

    return (this.modelDescription.step0 + ' ' +
        (this.modelDescription.step1isB
                ? st1
                : this.modelDescription.step1 + (this.isNullOrUndefined(this.modelDescription.step1) ? '' : this.modelDescription.step1lbl)
        ) + ' ' +
        (this.modelDescription.step2 ? 'INJ ' : '') +
        (!super.isNullOrUndefined(this.modelDescription.step3) && this.modelDescription.step3 > 1 ? this.modelDescription.step3 + '' : '') +
        this.modelDescription.step4 + ' ' +
        (this.modelDescription.step4isBulk ? 'BULK ' : '') +
        (!super.isNullOrUndefined(this.modelDescription.step5) ? this.modelDescription.step5 + ' ' : '') +
        (this.modelDescription.step6 ? 'AG ' : '') +
        this.modelDescription.step7).toUpperCase();
  }

  changeStepModalDescription(p: number) {
    this.modalDescriptionStep = p;
    $('#modalDescriptionForm').find('.nav a:eq(' + p + ')').tab('show');
  }

  getExcel() {
    const token = localStorage.getItem('token') as string;
    const culture = localStorage.getItem('culture') as string;
    const url = `${environment.base_url}/request/excel?requestId=${this.modelRequest.id}&token=${token}&culture=${culture}`;
    window.open(url);
  }

  bulkStateToogle() {
    this.modelRequest.bulk = this.modelDescription.step4isBulk;
  }

  setModelPortariaDescription() {
    const strFormat = this.modelDescriptionPortariaOrig.indexOf('(') <= 0 ?
        this.modelDescriptionPortariaOrig :
        this.modelDescriptionPortariaOrig.substring(0,
            this.modelDescriptionPortariaOrig.length - 4);
    if (!this.isNullOrUndefined(this.modelDescriptionPortaria)) {
      this.modelRequest.description = strFormat + '(' + this.modelDescriptionPortaria + ')';
    } else {
      this.modelRequest.description = strFormat;
    }
  }

  actionSendTab() {
    switch (this.step) {
      case 1:
        this.request1();
        break;
      case 2:
        this.request2();
        break;
      case 3:
        this.request3();
        break;
      case 4:
        this.request4();
        break;
    }
  }

  actionNextTab() {
    this.handlerNext(this.step);
  }

  handlerListIsBulk() {
    console.log('handlerListIsBulk');
    if (this.modelDescription.step4 !== 'CAP' && this.modelDescription.step4 !== 'CPR') {
      this.modelDescription.step4isBulk = false;
      this.modelRequest.bulk = false;
    }
    /*if (this.modelDescription.step4 === 'CAP' || this.modelDescription.step4 === 'CPR') {
        this.modelDescription.step4isBulk = false;
        this.modelRequest.bulk = false;
    } else {
        this.modelDescription.step4isBulk = false;
        this.modelRequest.bulk = false;
    }*/
  }

  handlerDateTitle(step: any) {
    const filter = this.modelRequest.history.filter(x => {
      if (step === 3 && x.step === 4 && Number(this.modelRequest.bulk) === 1) {
        x.step = 3;
        step = 3;
        return x.step === step;
      }
      return x.step === step;
    });
    if (!this.isNullOrUndefined(filter)) {
      return filter[0].createdAt;
    }
    return '';
  }

  handlerNameTitle(step: any) {
    const filter = this.modelRequest.history.filter(x => {
      return x.step === step;
    });
    if (!this.isNullOrUndefined(filter)) {
      return filter[0].createdBy.name;
    }
    return '';
  }

  // CHECK - COUNTRY
  handlerSelectedCountry(country: string, type: string = '') {
    if (this.isNullOrUndefined(type)) {
      if (this.isNullOrUndefined(this.modelRequest.countryNotifications)) {
        // @ts-ignore
        this.modelRequest.countryNotifications.push(country);
      } else {
        // @ts-ignore
        this.modelRequest.countryNotifications.forEach((x, i) => {
          if (x === country) {
            this.modelRequest.countryNotifications.splice(i, 1);
          }
        });
      }
    } else {
      // @ts-ignore
      const filter = this.modelRequest.countryNotifications.filter(x => x.country === country);
      if (this.modelRequest.countryNotifications.length <= 7 && this.isNullOrUndefined(filter)) {
        // @ts-ignore
        this.modelRequest.countryNotifications.push({country, type});
      } else {
        // @ts-ignore
        this.modelRequest.countryNotifications.forEach((x, i) => {
          // @ts-ignore
          if (x.country === country) {
            this.modelRequest.countryNotifications.splice(i, 1);
          }
        });
      }
    }
  }

  handlerCheckCountry(country, type: string = '') {
    if (type && !this.isNullOrUndefined(this.modelRequest.countryNotifications)) {
      // @ts-ignore
      return !this.isNullOrUndefined(this.modelRequest.countryNotifications.filter(x => x.country === country));
    } else if (!this.isNullOrUndefined(this.modelRequest.countryNotifications)) {
      return !this.isNullOrUndefined(this.modelRequest.countryNotifications.filter(x => x === country));
    }
  }

  handlerDisabledCountry(country, type: string = '') {
    if (this.isNullOrUndefined(type)) {
      if (!this.isNullOrUndefined(this.modelRequest.countryNotifications)) {
        return this.isNullOrUndefined(this.modelRequest.countryNotifications.filter(x => x === country));
      }
      return false;
    } else {
      if (!this.isNullOrUndefined(this.modelRequest.countryNotifications)) {
        // @ts-ignore
        const filter = this.modelRequest.countryNotifications.filter(x => x.type === undefined);

        if (!this.isNullOrUndefined(filter)) {
          // @ts-ignore
          return this.isNullOrUndefined(this.modelRequest.countryNotifications.filter(x => x.country === country));
        }

        // @ts-ignore
        if (this.modelRequest.countryNotifications.length >= 7) {
          // @ts-ignore
          return this.isNullOrUndefined(this.modelRequest.countryNotifications.filter(x => x.country === country));
        }
      }
    }
  }

  handlerCheckCountryVisible(type: string = '') {
    const arrCentro = ['brazil', 'colombia'];
    if (this.isNullOrUndefined(type)) {
      return arrCentro.includes(this.country) && !this.isCeam;
    } else {
      return arrCentro.includes(this.country) || this.isCeam;
    }
  }

  handlerSelectedCountryDescription(name: string, description: string = '') {
    this.modelDescription.step7 = name === 'CHI' ? 'CH' : name;
    this.modelRequest.country = description;
  }

  actionItemConcentration($event: any) {
    this.modelRequest.concentrationType = $event;
  }

  actionItemProductBrand($event: any) {
    this.modelRequest.productBrandType = $event;
  }

  actionArea($event: any) {
    this.modelRequest.area_400 = $event;
    console.log('actionArea');
    console.log(this.modelRequest.area_400);
    this.showStepOneCountries = this.modelRequest.area_400 === 'PMOINT';
    if (!this.showStepOneCountries) {
      this.modelRequest.countrySolicitationPmoInt = undefined;
      this.modelRequest.countryManufacturingPmoInt = undefined;
    }
  }

  actionChangeDescriptionConcentration(s: any, n: any) {
    if (n === 'step1lbl') {
      this.modelDescription.step1lbl = s;
    } else if (n === 'step1Blbl') {
      this.modelDescription.step1Blbl = s;
    }
  }

  actionisChangeDescriptionConcentration() {
    this.modelDescription.step1isB = !this.modelDescription.step1isB;
  }

  toogleIsCountryAdd() {
    this.isStepFourCountryAdd = !this.isStepFourCountryAdd;
  }

  handlerSelectedCountryAdd(name: string, description: string) {
    if (this.stepFourDisabled) {
      return;
    }
    const e: any = this.modelRequest.countryAdditional;
    const f = e.findIndex((v) => v === description);
    if (f < 0) {
      e.push(description);
    } else {
      e.splice(f, 1);
    }
  }

  isSelectedCountryAddActive(description: string) {
    return this.modelRequest.countryAdditional.filter((x) => x === description).length > 0;
  }

  hanlderShowCountryAdd(name: string) {
    let returnValue = true;
    if (this.modelRequest.countryNotifications.length === 1 && this.modelRequest.countryNotifications[0] === name) {
      returnValue = false;
    }
    return returnValue;
  }

  handlerDescriptionStepFour(event: string) {
    this.modelRequestResult.description = event;
  }

  handlerBlockLetters(event, step) {
    this.modelDescription[step] = event.replace(/[^0-9.,]+/, '');
  }

  actionResetStep() {
    const arrValidateFields = [
      {value: this.modelResetStep.step, text: `Etapa <br>`},
      {value: this.modelResetStep.motive, text: `Motivo <br>`}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('modal.alert.primary'), `${this.translate.instant('modal.alert.secondary')}: <br><br> ${stringError}`);
      return;
    }

    super.confirmMessage(this.translate.instant('general.atencao'), this.translate.instant('general.editStep'), () => {
      this.requestService.resetStep(this.modelRequest.id, this.modelResetStep).subscribe({
        next: data => {
          // this.modelRequest = data as Request;
          window.location.reload();
        }, error: err => super.onError(err)
      });
    });

  }

  getNameStep(x: number) {
    switch (x) {
      case 1:
        return this.translate.instant('general.step1');
        break;
      case 2:
        return this.translate.instant('general.step2');
        break;
      case 3:
        return this.translate.instant('general.step3');
        break;
      case 4:
        return this.translate.instant('general.step4');
        break;
      case 5:
        return this.translate.instant('general.step5');
        break;
    }
  }


  openModal(){
    $('#modalRegisterJustify').modal('show');
    this.modelRequest.descriptionFinish = '';
  }

  handlerSelectedCountryRequester(name: string, description: string) {
    if (this.stepOneDisabled) { return; }
    console.log('handlerSelectedCountryRequester');
    console.log(name);
    console.log(description);
    this.modelRequest.countrySolicitationPmoInt = description;
  }

  handlerSelectedCountryManufacturing(name: string, description: string) {
    if (this.stepOneDisabled) { return; }
    console.log('handlerSelectedCountryManufacturing');
    console.log(name);
    console.log(description);
    this.modelRequest.countryManufacturingPmoInt = description;
  }
}
